import moment from 'moment';
import Jsona from 'jsona';
import camelCase from 'lodash/camelCase';
import isObject from 'lodash/isObject';
import qs from 'qs';
import { AxiosError } from 'axios';

import flatpickr from 'flatpickr';
import React from 'react';
import messageTemplateFn from '../templates/messages/message.hbs';
import CurrencyFormat from 'react-currency-format';

const utilities = {
  // use these when we have multiple, possibly unrelated blockers, and we want to wait for all of them to finish
  blockerLocks: {},

  fetch(url, data = {}, type = 'get') {
    let request;
    const deferred = $.Deferred();
    if (type === 'get') {
      request = $.get(url, data, $.noop, 'json');
    } else {
      request = $.post(url, data, $.noop, 'json');
    }

    request.done(function (r) {
      if (r.success === true) {
        const fullResponse = _.clone(r);
        deferred.resolve(r.data, fullResponse);
      } else {
        let errors = ['Something else went wrong'];
        if (r.hasOwnProperty('errors') === true) {
          errors = r.errors;
        }
        deferred.reject({
          errors,
        });
      }
    });

    request.fail(function (rjqXHR, textStatus, errorThrown) {
      let errors = ['Something else went wrong'];
      if (rjqXHR.responseJSON) {
        errors = rjqXHR.responseJSON.errors;
      }

      deferred.reject({
        errors,
      });
    });

    return deferred.promise();
  },
  notify(options) {
    UIkit.notification(options);
  },

  /**
   * UIKit notification wrapper
   */
  notification: {
    // primary notification
    primary: (message) => {
      UIkit.notification(message, { status: 'primary' });
    },
    // info notification
    info: (message) => {
      UIkit.notification(message, { status: 'info' });
    },
    // success notification
    success: (message) => {
      UIkit.notification(message, { status: 'success' });
    },
    // warning notification
    warning: (message) => {
      UIkit.notification(message, { status: 'warning' });
    },
    // danger notification
    danger: (message) => {
      UIkit.notification(message, { status: 'danger' });
    },
  },

  copyToClipboard: (el, successMessage = 'Copied to clipboard') => {
    const cp = new Clipboard(el);
    cp.on('success', function (e) {
      utilities.notification.success(successMessage);
    });
  },
  displaySpinner($element, size) {
    $element.html(
      `<div class="uk-text-center uk-padding-large"><div uk-spinner="ratio:${size}"></div></div>`
    );
  },

  message({
    $element,
    title,
    message,
    spinner,
    icon,
    errors,
    layout,
    errorFormat,
    iconClasses,
  }) {
    $element.html(
      messageTemplateFn({
        title,
        message,
        spinner,
        icon,
        errors,
        layout,
        errorFormat,
        iconClasses,
      })
    );
  },

  notifyErrors(errors) {
    let html = '<ul>';
    html += `${$.map(errors, function (e) {
      if (typeof e === 'object') {
        return `<url>${$.map(e, function (sub_e) {
          return `<li>${sub_e}</li>`;
        }).join('')}</ul>`;
      }
      return `<li>${e}</li>`;
    }).join('')}</ul>`;

    utilities.notify({
      message: `<h4>The following errors occurred</h4>${html}`,
      status: 'danger',
      timeout: 5000,
      pos: 'top-center',
    });
  },
  notifyLaravelResponseErrors(res) {
    let arrErrors = [''];
    if (res.status == 500) {
      arrErrors = ['Error 500: There was a problem with the script.'];
    } else if (res.status == 401) {
      arrErrors = ['Session Expired: Redirecting'];
    } else if (res.status == 404) {
      arrErrors = ['Error 404: Page not found'];
    } else if (res.status == 422) {
      arrErrors = res.responseJSON;
    } else {
      arrErrors = [`Error ${res.status}`];
    }
    utilities.notifyErrors(arrErrors);
  },
  blockPage(message, show_spinner, style) {
    window.spinnerBlocker.block(message, show_spinner, style);
  },
  unblockPage() {
    const allLocksClear = _.every(this.blockerLocks, function (value) {
      return value;
    });

    if (allLocksClear) {
      window.spinnerBlocker.unblock();
    }
  },
  /**
   * Displaying an errors or a list of errors using jquery-confirm alert modal
   * @param {array|object|string} errors
   * @param {Function} [onClose] Is called when the modal is going to be closed.
   * @throws jquery-confirm alert modal
   */
  alertErrors(errors, onClose) {
    if (Array.isArray(errors) && errors !== null) {
      errors = errors.map((n) => `<li>${n}</li>`).join('');
    }
    if (typeof errors === 'object' && errors !== null) {
      errors = Object.values(errors)
        .map((n) => `<li>${n}</li>`)
        .join('');
    }
    $.alert({
      title: 'Error',
      closeIcon: true,
      confirmButtonClass: 'uk-hidden',
      content: `
                        <p>One or more errors have occured, please review the items below:</p>
                        <ul class="uk-list uk-list-divider">${errors}</ul>
                    `,
      onClose,
    });
  },

  alert(message, onClose, title) {
    $.alert({
      title: title || 'Alert',
      content: message,
      onClose,
    });
  },
  prompt(
    title,
    message,
    label,
    onClose,
    confirmButtonText,
    confirmButtonClass,
    cancelButtonText,
    cancelButtonClass
  ) {
    $.confirm({
      title,
      confirmButton: confirmButtonText || 'Save',
      confirmButtonClass: confirmButtonClass || 'btn btn-green',
      cancelButton: cancelButtonText || 'Cancel',
      cancelButtonClass: cancelButtonClass || 'btn-outline',
      closeIcon: true,
      content:
        `${
          '' +
          '<form action="" class="formName">' +
          '<div class="form-group">' +
          '<p>'
        }${message}</p>` +
        `<label>${label}</label>` +
        `<input type="text" placeholder="Your name" class="name form-control" required />` +
        `</div>` +
        `</form>`,
      confirm() {
        const name = this.$content.find('.name').val();
        if (!name) {
          $.alert('provide a valid name');
          return false;
        }
        onClose(name);
      },
      cancel() {
        // close
      },
    });
  },
  confirm(
    message,
    callback,
    title = 'Confirm',
    confirmButtonText = 'Yes',
    confirmButtonClass = 'btn btn-green',
    cancelButtonText = 'No',
    cancelButtonClass = 'uk-hidden'
  ) {
    $.confirm({
      title,
      content: message,
      confirmButton: confirmButtonText,
      confirmButtonClass,
      cancelButton: cancelButtonText,
      cancelButtonClass,
      closeIcon: true,
      confirm() {
        callback(true);
      },
      cancel() {
        callback(false);
      },
    });
  },
  dismissNotifications() {
    UIkit.notification.closeAll();
  },
  // check https://momentjs.com/docs/#/displaying/
  //
  // YYYY-MM-DD
  // 2016-12-03
  // YYYY-MM-DD hh:mm:ss
  // 2013-02-13 09:30:26
  /**
   * current use moment to format the date
   * @param  {String} date   in the format (https://momentjs.com/docs/#/parsing/)
   * @param  {boolean} isUsingTimeZone check using timezone server
   * @param  {String} format the format you use to convert
   * @return {String} the formatted date as a string
   */
  formatDate(date, format, isUsingTimeZone = false) {
    const timeZone = window?.pulse?.config?.user?.timezone;
    const dateFormat = (
      isUsingTimeZone && !!timeZone ? moment(date).tz(timeZone) : moment(date)
    ).format(format);

    return dateFormat;
  },

  userPop(mjobid, refresh, url, mwidth, mheight, mwindowName) {
    if (!url) url = `jobgroup.php?action=invite&pop=true&jobid=${mjobid}`;
    if (!mwidth) mwidth = 800;
    if (!mheight) mheight = 450;
    if (!mwindowName) mwindowName = 'admin';

    if (mwindowName == 'dynamic') mwindowName = new Date().getTime();
    const featureString = `scrollbars,width=${mwidth},height=${mheight}`;
    window.open(url, mwindowName, featureString);
  },
  truncate(str, length, ending) {
    if (length == null) {
      length = 100;
    }
    if (ending == null) {
      ending = '...';
    }
    if (str.length > length) {
      return str.substring(0, length - ending.length) + ending;
    }
    return str;
  },

  /**
   * Send updates to google analytics
   * When page url is changed
   *
   * @return null
   */
  sendAnalyticData() {
    const path = (window.location.pathname + window.location.search).substr(1);

    if (window.dataLayer && window.dataLayer.push) {
      window.dataLayer.push({
        event: 'page_view',
        pagePath: path,
        user_id: window.pulse.config.user.id,
        account_id: window.pulse.config.user.accountid,
        client_id: window.pulse.config.user.clientid,
        brands: window.pulse.request.brands ? window.pulse.request.brands : '',
      });
    }
  },

  /**
   * Gets the query string as an key paired parameters object.
   *
   * @param {string} key
   * @param {string} def
   *
   * @return {Object}
   */
  getQueryStringParameters(key = undefined, def = undefined) {
    // initialize empty params object
    const params = {};
    // trimmed query string
    const string = window.location.search.substring(1).trim();
    // when the trimmed query string contains something
    if (string.length) {
      // key pairs
      const pairs = window.location.search.substring(1).split('&');
      // step through each key pair
      for (let i = 0; i < pairs.length; i++) {
        // tokens for current query part
        const tokens = decodeURI(pairs[i]).split('=');
        // split the key paired parts
        params[tokens[0]] = tokens[1];
        // when this key token matches the specified key
        if (key === tokens[0]) {
          // return the key pair value
          return tokens[1];
        }
      }
    }
    // return the compiled parameters or the specified default value as applicable
    return typeof key === 'undefined' ? params : def;
  },

  /**
   * Sets the browser history.
   *
   * @param {object} queries
   * @param {string} title
   * @param {boolean} replace
   *
   * @returns {void}
   */
  setBrowserHistory(queries, title = '', replace = false) {
    // compile the applicable query parameters
    let parameters = Object.assign(this.getQueryStringParameters(), queries);
    // filter out empty parameters
    parameters = _.omit(parameters, function (value) {
      return value === '' || typeof value === 'undefined' || value === null;
    });
    // when there are parameters to append as a query string
    if (Object.keys(parameters).length) {
      // replace or push the browser history state accordingly
      if (replace) {
        // replace the query string to the navigation history
        window.history.replaceState(
          { html: '', pageTitle: '' },
          title,
          `?${jQuery.param(parameters)}`
        );
      } else {
        // append the query string to the navigation history
        window.history.pushState(
          { html: '', pageTitle: '' },
          title,
          `?${jQuery.param(parameters)}`
        );
      }
    } else {
      // reset the query string in the navigation history
      window.history.replaceState(null, title, window.location.pathname);
    }
  },

  createCookie(name, value, days) {
    let expires = '';
    if (days) {
      const date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = `; expires=${date.toGMTString()}`;
    }

    document.cookie = `${name}=${value}${expires}; path=/`;
  },

  readCookie(name) {
    const nameEQ = `${name}=`;
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  },

  eraseCookie(name) {
    this.createCookie(name, '', -1);
  },

  filterItems(element, filter) {
    const $items = $(element);
    $(filter).keyup(function () {
      const re = new RegExp($(this).val(), 'i'); // "i" means it's case-insensitive
      $items
        .show()
        .filter(function () {
          return !re.test($(this).text());
        })
        .hide();
    });
  },

  translations(key) {
    if (key in window.pulse.globals.translations)
      return window.pulse.globals.translations[key];
    return '';
  },
  htmlDecode(input) {
    const doc = new DOMParser().parseFromString(input, 'text/html');
    return doc.documentElement.textContent;
  },
  checkRequired($form, warningClass, requiredAttr) {
    let proceed = true;
    $(warningClass).addClass('uk-hidden');

    $form.find('input,select,textarea').each(function () {
      if ($(this).attr(requiredAttr)) {
        if (!$(this).val()) {
          proceed = false;
          $(this).addClass('uk-form-danger');
          $(this).parent().find(warningClass).removeClass('uk-hidden');
        }
      }
    });

    return proceed;
  },
  renameKeys(obj, newKeys) {
    const keyValues = Object.keys(obj).map((key) => {
      const newKey = newKeys[key] || key;
      return { [newKey]: obj[key] };
    });
    return Object.assign({}, ...keyValues);
  },
  /**
   * @param {AxiosError} errors
   */
  notifyJsonApiErrors(errors) {
    const errorMessages = errors?.response?.data.errors.map(
      (error) => error.detail
    );
    this.notifyErrors(errorMessages);
  },
};

export default utilities;

export const stringifyQueryString = (params, options = {}) => {
  return qs.stringify(params, {
    encode: false,
    addQueryPrefix: true,
    arrayFormat: 'brackets',
    ...options,
  });
};

export const USER_NOTIFICATION_CUSTOM_ENDPOINT_TYPES = {
  WIKI_SHARE_PAGE_VIA_EMAIL: 'WIKI_SHARE_PAGE_VIA_EMAIL',
};

export const PROJECT_WIZARD_STEPS_ID = {
  REVIEW: 6,
};

export const REPORT_TYPE = {
  DETAIL: 'finance-detail-report',
  SUMMARY: 'finance-summary-report',
};

export const deepLinkToFilter = (_key, el) => {
  const { deepLinks } = pulse.request;
  el.find('option').remove();
  if (Object.keys(deepLinks).indexOf(_key) !== -1) {
    const deepLinkData = deepLinks[_key];
    for (const optionValue in deepLinkData) {
      const option = new Option(
        deepLinkData[optionValue],
        optionValue,
        true,
        true
      );
      el.append(option).trigger('change');
    }
  }
};

export const filterToURL = (newFilter) => {
  const _params = new URLSearchParams(window.location.search);
  if (newFilter instanceof Array) {
    newFilter.map((filter) => {
      for (const key in filter) {
        _params.append(key, filter[key]);
      }
    });
  } else {
    for (const key in newFilter) {
      _params.append(key, newFilter[key]);
    }
  }
  window.history.pushState(
    '',
    '',
    `${window.location.pathname}?${decodeURI(_params)}`
  );
  return _params;
};

export const removeFromURL = (searchParams, key, value) => {
  const spa = searchParams
    .filter((sp) => {
      return sp[1] !== value;
    })
    .map((sp) => {
      return sp.join('=');
    })
    .flat()
    .join('&');

  window.history.pushState(
    '',
    '',
    decodeURI(`${window.location.pathname}?${new URLSearchParams(spa)}`)
  );
  return new URLSearchParams(spa);
};
export const initialiseFlatpicker = (element, options) => {
  const flatpickrDateFormat = window.pulse.config.flatpickrDateFormat();

  const initFlatpicker = (el) => {
    flatpickr(el, {
      dateFormat: flatpickrDateFormat,
      ...options,
    });
  };

  if (Array.isArray(element)) {
    element.map((elem) => {
      initFlatpicker(elem);
    });
  } else {
    initFlatpicker(element);
  }
};

export const getPulseEditorContentCss = () => {
  return `/v2/${
    process.env.NODE_ENV === 'development' ? 'dev' : 'build'
  }/css/pulse-editor/pulse-editor-content.css`;
};

export const getPdfViewerHtmlUrl = () => {
  return `/v2/${
    process.env.NODE_ENV === 'development' ? 'dev' : 'build'
  }/vendors/pdf-js/web/viewer.html?file=`;
};

export const getPdfWorkerUrl = () => {
  return `/v2/${
    process.env.NODE_ENV === 'development' ? 'dev' : 'build'
  }/vendors/pdf-js/build/pdf.worker.js`;
};

export const parseJsonApi = (response) => {
  const formatter = new Jsona();
  return formatter.deserialize(response);
};

export const getTaskStatusChangePermission = (permission) => {
  return (
    permission.changeTaskStatus === 'y' &&
    (permission.manageTasks === 'y' || permission.category === 'General User')
  );
};

/**
 * Navigate to url with hash
 * @param {string} hash
 */
export const jumpToHash = (hash) => {
  window.location.href = `${window.location.origin}${window.location.pathname}#${hash}`;
};

export const webpackPublicPath = () =>
  process.env.NODE_ENV === 'production' ? '/v2/build/' : '/v2/dev/';

/**
 * Convert file size to correct unit
 * @param {number} size
 * */
export const convertFileSize = (size) => {
  const i = Math.floor(Math.log(size) / Math.log(1024));
  return `${(size / 1024 ** i).toFixed(2) * 1} ${['kb', 'mb', 'gb', 'tb'][i]}`;
};
export const REPORT_DATE_FORMAT = 'DD-MM-YYYY';

export const DATE_RANGE_TYPE = {
  THIS_WEEK: 1,
  LAST_7_DAYS: 2,
  THIS_MONTH: 3,
  LAST_MONTH: 4,
  LAST_3_MONTHS: 5,
  YTD: 6,
  LAST_YEAR: 7,
  CUSTOM_DATES: 8,
};

/**
 *   Handle Date range base on date range type, start date and end date
 *   @param {number} dateRangeType
 *   @param {string | null} startDate
 *   @param {string | null} endDate
 *   @return {string | null}
 */
export const handleReportDateRange = (
  dateRangeType,
  startDate = null,
  endDate = null
) => {
  const formatedDateRangeType = parseInt(dateRangeType);
  const isValidDateRangeType = Object.values(DATE_RANGE_TYPE).includes(
    formatedDateRangeType
  );

  if (!isValidDateRangeType) {
    return null;
  }

  const today = moment().format(REPORT_DATE_FORMAT);
  switch (formatedDateRangeType) {
    case DATE_RANGE_TYPE.THIS_WEEK:
      return `${moment().day(1).format(REPORT_DATE_FORMAT)} to ${today}`;
    case DATE_RANGE_TYPE.LAST_7_DAYS:
      return `${moment()
        .subtract(7, 'days')
        .format(REPORT_DATE_FORMAT)} to ${today}`;
    case DATE_RANGE_TYPE.THIS_MONTH:
      return `${moment()
        .startOf('month')
        .format(REPORT_DATE_FORMAT)} to ${today}`;
    case DATE_RANGE_TYPE.LAST_MONTH:
      return `${moment()
        .subtract(1, 'months')
        .startOf('month')
        .format(REPORT_DATE_FORMAT)} to ${today}`;
    case DATE_RANGE_TYPE.LAST_3_MONTHS:
      return `${moment()
        .subtract(2, 'months')
        .startOf('month')
        .format(REPORT_DATE_FORMAT)} to ${today}`;
    case DATE_RANGE_TYPE.YTD:
      return `${moment()
        .startOf('year')
        .format(REPORT_DATE_FORMAT)} to ${today}`;
    case DATE_RANGE_TYPE.LAST_YEAR:
      const endOfLastYear = moment()
        .subtract(1, 'year')
        .endOf('year')
        .format(REPORT_DATE_FORMAT);
      const startOfLastYear = moment()
        .subtract(1, 'year')
        .startOf('year')
        .format(REPORT_DATE_FORMAT);
      return `${startOfLastYear} to ${endOfLastYear}`;
    case DATE_RANGE_TYPE.CUSTOM_DATES:
      startDate = startDate
        ? moment(startDate).format(REPORT_DATE_FORMAT)
        : null;
      endDate = endDate ? moment(endDate).format(REPORT_DATE_FORMAT) : null;
      return `${startDate}${endDate ? ` to ${endDate}` : ''}`;
    default:
      return null;
  }
};

/**
 *   Handle DateFormat are in Unix format, should be in the format defined in the user's session (eg: UK or US Date)
 *   @param {string | Date} value
 *   @param {boolean} isUsingTimeZone
 *   @return {string | null}
 */
export const handleDateFormat = (value, isUsingTimeZone = false) => {
  if (!moment(value).isValid()) {
    return null;
  }
  const dateFormat = window?.pulse?.config?.dateFormat();

  return utilities.formatDate(value, dateFormat, isUsingTimeZone);
};

export const REQUEST_STATUS = {
  pending: 'pending',
  fulfilled: 'fulfilled',
  rejected: 'rejected',
};

export const REQUEST_HEADER = {
  Accept: 'application/vnd.api+json',
  'Content-Type': 'application/vnd.api+json',
};

/**
 * This is used for checking the error state of fields in formik
 * @param {Object} fieldErrors
 * @param {Object} fieldTouched
 * @param {string} field
 * @returns
 */
export const isFieldValid = (fieldErrors, fieldTouched, field) => {
  const keys = field.split('.'); // Splitting all keys in string;

  if (keys.length > 1) {
    let error = fieldErrors;
    let touched = fieldTouched;

    keys.every((key) => {
      if ((typeof error || typeof touched) !== 'object') return false;

      touched = (touched.hasOwnProperty(key) && touched[key]) || false;
      error = (error.hasOwnProperty(key) && error[key]) || false;
      return true;
    });

    return !!touched && !!error;
  }

  return !!fieldErrors[field] && !!fieldTouched[field];
};

/**
 * handle convert key of object or Array become camel case
 * @param {object | array} obj
 * @returns {Object.<string, any>}
 */
export const camelizeKeys = (dataTransform) => {
  if (Array.isArray(dataTransform)) {
    return dataTransform.map((v) => camelizeKeys(v));
  }
  if (isObject(dataTransform)) {
    return Object.keys(dataTransform).reduce(
      (result, key) => ({
        ...result,
        [camelCase(key)]: camelizeKeys(dataTransform[key]),
      }),
      {}
    );
  }
  return dataTransform;
};

/**
 * Custom hook using for polling request
 * @param {Function} callback
 * @param {number} delay
 * @returns {number}
 */
export const useInterval = (callback, delay) => {
  return setInterval(callback, delay);
};

export const { config = {}, request: jsRequest = {} } = window?.pulse || {};

export const {
  urls: URLS,
  user: USER,
  dateFormat,
  request: REQUEST,
} = config || {};

/**
 * Get short date format based on country
 * e.x: M/d/y
 * @return {string}
 */
export const dateFormatBasedOnCountry = () => {
  const { countryShortDateFormat } = USER || {};

  // Replace m by M and  d by dd, because some libraries like date-fns only use M for months instead of m
  // and date-fns use d for 1, 2, ..., 31 but we need to
  // show zero before for a digit number like 01, 02, ..., 31
  const mapObj = {
    Y: 'y',
    m: 'M',
    d: 'dd',
  };

  return countryShortDateFormat
    ? countryShortDateFormat.replace(
        /\b(?:d|m|Y)\b/gi,
        (matched) => mapObj[matched] ?? matched
      )
    : dateFormat();
};

export const PRIMARY_OFFICE_ID = USER?.clientid;
export const PRIMARY_OFFICE_NAME = USER?.clientname;

const SUPER_USER_ACCESS = 'superuser';

export const isSuperUser = USER?.access === SUPER_USER_ACCESS;

export const SORT_ASCENDING = 'asc';

export const SORT_DESCENDING = 'desc';

/**
 * Get format of decimal separator on user office
 * @param {number | string} number
 * @param {string} type
 * @param {number} decimal
 * @returns {string}
 */
export const getFormatNumber = (number, type, decimal) => {
  const formatter = new Intl.NumberFormat(type === '.' ? 'pt-BR' : 'en-US', {
    maximumFractionDigits: decimal,
    minimumFractionDigits: decimal,
  });

  return formatter.format(Number(number));
};

export const determineArticle = (word) => {
  // Define the regex patterns
  const vowelSoundPattern = /^[aeiouAEIOU]|^h(?!o(?:nor|ur))/i;
  const specialCasesPattern =
    /^(honest|honor|hour|one|eulogy|uniform|universe)/i;

  // Check the word against the patterns
  if (specialCasesPattern.test(word)) {
    // Handle known exceptions
    if (/^(honest|honor|hour)/i.test(word)) {
      return `an ${word}`;
    }
    if (/^(one|eulogy|uniform|universe)/i.test(word)) {
      return `a ${word}`;
    }
  } else if (vowelSoundPattern.test(word)) {
    return `an ${word}`;
  }
  return `a ${word}`;
};

export const formatUserByJQ = `{
  data: [
      .included as $included |
      .data[] as $data |
      $data.attributes as $attributes |
      {
          id: $data.id,
          type: $data.type,
          attributes: (
              $attributes |
              .resource_id = (
                  $included[] | select(.id == $data.relationships["agresso-user"].data.id) | .attributes.resource_id
              )
          )
      }
  ]
}`;

export const renderFormatNumberBySeparator = (
  value,
  thousandSeparator,
  decimalSeparator,
  options
) => {
  
  return <CurrencyFormat
    displayType="text"
    value={value}
    thousandSeparator={thousandSeparator}
    decimalSeparator={decimalSeparator}
    {...options}
  />
};
