import getHours from 'date-fns/getHours';
import getMinutes from 'date-fns/getMinutes';
import * as yup from 'yup';

const compareTime = (startDate: Date, endDate: Date): boolean => {
  const startHours = getHours(startDate);
  const endHours = getHours(endDate);
  if (startHours === endHours) {
    const startMinutes = getMinutes(startDate);
    const endMinutes = getMinutes(endDate);
    return startMinutes <= endMinutes;
  }
  return startHours < endHours;
};

export const BOOKED_USER_ERROR = '* Please select a user';
export const JOB_ERROR = '* Please select a project';

const EditFormValidationSchema = yup.object().shape(
  {
    job: yup.object().shape({
      value: yup.string().required(JOB_ERROR),
    }),
    timesheetActivity: yup.object().shape({
      value: yup.string().required('* Please select an activity'),
    }),
    bookedUser: yup.object().shape({
      value: yup.string().required(BOOKED_USER_ERROR),
    }),
    requestedBy: yup.array().ensure().min(1, '* Please select at least one user'),
    startDate: yup
      .date()
      .required('* Start date is required')
      .typeError('* Start date is Invalid')
      .when('endDate', (endDate, schema) => {
        return schema.isType(endDate)
          ? schema
              .test('startTime', '* Start time cannot be after end time', value => {
                return compareTime(value, endDate);
              })
              .max(endDate, '* The start date cannot be after the end date.')
          : schema;
      }),
    endDate: yup
      .date()
      .required('* End date is required')
      .typeError('* End date is Invalid')
      .when('startDate', (startDate, schema) => {
        return schema.isType(startDate)
          ? schema
              .test('endTime', '* End time cannot be before start time', value => {
                return compareTime(startDate, value);
              })
              .min(startDate, '* The end date cannot be before the start date.')
          : schema;
      }),
    hoursPerDay: yup
      .number()
      .max(23.95)
      .min(0.0)
      .positive('* Invalid hours per day')
      .test('quarterStep', '* Increments of 0.25', value => !!value)
      .required('* Hours per day are required'),
  },
  [['endDate', 'startDate']],
);

export default EditFormValidationSchema;
