import React, { ReactElement, Suspense, lazy, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { PulseDatePicker } from 'components/pulse-date-picker/pulse-datepicker';
import { PulseProjectsSelect } from 'components/pulse-select/pulse-projects/pulse-projects-select';
import { PulseProjectUsersSelect } from 'components/pulse-select/pulse-users/pulse-users-select';
import PulseSelectBase from 'components/pulse-select/base/pulse-select-base';
import styles from './booking-details.module.scss';
import { Divider } from '@mui/material';
import Slider from '@mui/material/Slider';
import PulseTimePicker from 'components/pulse-timepicker/pulse-timepicker';
import clsx from 'clsx';
import PulseEditor from 'components/pulse-editor/pulse-editor';
import { debounce } from 'lodash';
import { FormikProps, useFormikContext } from 'formik';
import { BOOKED_USER_ERROR, JOB_ERROR } from './edit-form-validation-schema';
import { OptionType, OptionWithRawType, OverBookingList } from './booking-types';
import { CalendarModel } from '@bryntum/schedulerpro-thin';
import {
  calculateTotalHours,
  DEFAULT_MAX_HOURS,
  ResourcePlannerState,
  validBookingDate,
} from '../reducers/resource-planner-reducer';
import endOfDay from 'date-fns/endOfDay';
import { calculateHours } from './edit-form';
import {
  addHours,
  addMilliseconds,
  addMinutes,
  differenceInDays,
  eachWeekendOfInterval,
  isSameDay,
  isValid,
  roundToNearestMinutes,
  set,
} from 'date-fns';
import { PulseDatePickerState } from 'components/pulse-date-picker/reducer/pulse-datepicker-reducer';
import PulseTooltip from 'components/pulse-tooltip/pulse-tooltip';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import { useResourcePlannerState } from '../context/resource-planner-context';
import QueryString from 'qs';
import { PulseEditorProps } from 'components/pulse-editor/pulse-editor-types';
import { v2Endpoint } from 'pulse-api/base';
import { PULSE_LOCAL_URL } from 'pulse-commons/helpers';
import { statusStaticOptions } from 'pulse-commons/status-options';
import BookingColor from './BookingColor/booking-color';
import BookingHoursInput from './BookingHoursInput/booking-hours-input';

export interface BookingDetailsProps {
  formikProps: FormikProps<any>;
  resourceCalendar?: CalendarModel;
  isNew?: boolean;
  isOverbook?: boolean;
  overBookingList: OverBookingList[] | [];
  isProcessingOverbooking: boolean;
  isNonChargeTime?: boolean;
  primaryClient?: {
    clientId: string | number;
    company: string;
  };
  allOfficesIds?: string[];
  PulseEditorProps?: PulseEditorProps;
  // handleSetReInitialValues: (newInitialValues: Record<string, any>) => void;
}

const BOOKED_USER_URL = `/v2/api/users?include=defaultTimeSheet`;

const setTimeForADate = (date: Date, time: Date): Date => {
  const updatedDate = date.setHours(time.getHours(), time.getMinutes(), time.getSeconds());
  return new Date(updatedDate);
};

const BOOKED_USER_DATA_STRUCTURE = {
  dataKey: 'data',
  isJsonApi: true,
  isJQ: true,
  label: 'label',
  lastPage: 'meta.page.last_page',
  pageSize: 10,
  type: 'users',
  value: 'value',
};

const convertData = (arrayData: any[]): OptionType[] => {
  return arrayData.map(each => {
    return {
      label: each.activityname,
      value: each.id,
    };
  });
};

const BookingDetails = ({
  formikProps,
  resourceCalendar,
  isNew,
  isOverbook,
  overBookingList,
  isProcessingOverbooking,
  allOfficesIds,
  isNonChargeTime,
  PulseEditorProps = {},
}: // handleSetReInitialValues,
BookingDetailsProps): ReactElement => {
  // state
  const rpState: ResourcePlannerState = useResourcePlannerState();
  const { values: valuesContext, setSubmitting } = useFormikContext<Record<string, any>>();

  const [errorProject, setErrorProject] = useState<boolean>(false);
  const [errorBookedUser, setErrorBookedUser] = useState<boolean>(false);
  const [errorActivity, setErrorActivity] = useState<boolean>(false);
  const [maxHours, setMaxHours] = useState<number>(
    Number(formikProps?.values?.bookedUser?.raw?.maxHours || DEFAULT_MAX_HOURS),
  );
  const [sliderRatio, setSliderRatio] = useState<number>(10);
  const [showActivityField, setShowActivityField] = useState<boolean>(false);
  const [activityUrl, setActivityUrl] = useState<string>('');
  const [activityOptions, setActivityOptions] = useState<OptionType[]>([]);
  const bookingHoursInputRef = useRef();
  const sliderValue = useRef(formikProps?.values?.hoursPerDay);

  // variables
  const isSingleDay = isSameDay(formikProps?.values?.startDate, formikProps?.values?.endDate);

  const totalDays = useMemo(() => {
    // the total is 1 when same days
    const differenceDays = differenceInDays(formikProps?.values?.endDate, formikProps?.values?.startDate) + 1;

    // total days should ignore weekend ( non working days )
    const countNonWorkingDays = eachWeekendOfInterval({
      end: formikProps?.values?.endDate,
      start: formikProps?.values?.startDate,
    }).length;
    if (countNonWorkingDays) {
      return differenceDays - countNonWorkingDays;
    }
    return differenceDays;
  }, [formikProps?.values?.endDate, formikProps?.values?.startDate]);

  const totalDaysText = useMemo(() => {
    if (isNaN(totalDays)) {
      return '0.00';
    }

    return totalDays.toFixed(2);
  }, [totalDays]);

  useEffect(() => {
    // if (isNew && formikProps.values.bookedUser) {
    //   const hours = calculateHours(
    //     roundToNearestMinutes(formikProps.values.startDate, { nearestTo: 1 }),
    //     roundToNearestMinutes(formikProps.values.endDate, { nearestTo: 1 }),
    //   );
    //   const days = Number(formikProps.values.totalHours) / Number(formikProps.values.hoursPerDay);

    //   formikProps.setValues({
    //     ...formikProps?.values,
    //     hoursPerDay: hours,
    //     totalHours: Number(hours) * days,
    //   });
    // }

    if (!isNew) {
      formikProps.setFieldValue('endDate', addMilliseconds(formikProps.values.endDate, 1));
      formikProps.values.bookedUser.raw.maxHours && setMaxHours(Number(formikProps.values.bookedUser.raw.maxHours));
    }
  }, []);
  /**
   * This effect update for end date when on single day at Edit mode
   */
  // useEffect(() => {
  //   if (isSingleDay && !isNew) {
  //     const hoursPerDay =
  //       formikProps.values.hoursPerDay ||
  //       Number(calculateHours(formikProps.values.startDate, formikProps.values.endDate));
  //     const newTimeEndDate = recalculateEndHours(formikProps.values.startDate, hoursPerDay);
  //     formikProps.setFieldValue('endDate', newTimeEndDate);
  //   }
  // }, [isSingleDay]);

  const handleChangeProject = async (value: Record<'projectId', OptionType>): Promise<void> => {
    if (!value.projectId) {
      formikProps?.setFieldValue('timesheetActivity', undefined);
    } else {
      setErrorProject(false);
    }
    formikProps?.setFieldValue('job', value.projectId);
  };

  const handleChangeUser = async (value: Record<'userId', OptionWithRawType>): Promise<void> => {
    if (!value.userId) {
      formikProps?.setFieldValue('timesheetActivity', undefined);
    } else {
      setErrorBookedUser(false);
      if (isNonChargeTime) {
        const { data } = await v2Endpoint.get(`${PULSE_LOCAL_URL}/v2/api/clients/${value.userId.raw.clientid}`);
        if (data?.data?.attributes?.pto_jobid) {
          formikProps?.setFieldValue('job', {
            value: data.data.attributes.pto_jobid,
            label: data.data.attributes.pto_jobtitle,
          });
        }
      }
      if (value.userId.raw.defaultTimesheetActivity && !value.userId.raw.isAgresso) {
        formikProps?.setFieldValue('timesheetActivity', {
          value: `${value.userId.raw.defaultTimesheetActivity.timesheetactivityid}`,
          label: value.userId.raw.defaultTimesheetActivity.name,
        });
        setErrorActivity(false);
      } else {
        formikProps?.setFieldValue('timesheetActivity', null);
      }
      Number(value.userId.raw.maxHours) && formikProps.setFieldValue('hoursPerDay', Number(value.userId.raw.maxHours));
      sliderValue.current = Number(value.userId.raw.maxHours);
      // validate hours per day with max user hours
      if (Number(value.userId.raw.maxHours)) {
        setMaxHours(Number(value.userId.raw.maxHours));
        formikProps?.setFieldValue?.('hoursPerDay', Number(value.userId.raw.maxHours));
        const hours = calculateHours(
          roundToNearestMinutes(new Date(formikProps.values.startDate), { nearestTo: 1 }),
          roundToNearestMinutes(new Date(formikProps.values.endDate), { nearestTo: 1 }),
        );
        const unwantedHours = Number(hours) - Number(value.userId.raw.maxHours);
        if (unwantedHours > 0 && isSingleDay) {
          const newEndDate = addMinutes(formikProps.values.endDate, -60 * unwantedHours);
          formikProps.setFieldValue('endDate', newEndDate);
        } else {
          formikProps.setFieldValue('totalHours', Number(value.userId.raw.maxHours) * totalDays);
        }
      } else {
        setMaxHours(Number(DEFAULT_MAX_HOURS));
      }

      // update hours per day with same ratio with before user
      formikProps.setFieldValue(
        'hoursPerDay',
        (sliderRatio * Number(value.userId.raw.maxHours || DEFAULT_MAX_HOURS)) / 10,
      );
    }
    formikProps?.setFieldValue('bookedUser', value.userId);
  };

  // TODO: check is there more than 1 activities available for the project and user
  const checkAvailableActivity = async () => {
    formikProps?.setSubmitting(true);
    if (formikProps.values.bookedUser && formikProps.values.job) {
      await v2Endpoint
        .get(
          `${PULSE_LOCAL_URL}/resource-planner.php?action=get-activities-for-bookings&jobid=${formikProps.values.job.value}&userid=${formikProps.values.bookedUser.value}&useDefaultResourceBookingActivity=true`,
        )
        .then(res => {
          if (res.data?.data?.length > 1) {
            setShowActivityField(true);
            setActivityOptions([...convertData(res.data.data)]);
          } else {
            if (res.data?.data[0]) {
              // remove formikProps?.setFieldValue('timesheetActivity' because it's incorrect formik concept
              // handleSetReInitialValues({
              //   ...formikProps.values,
              //   timesheetActivity: {
              //     value: res.data.data[0]?.id,
              //     label: res.data.data[0]?.activityname,
              //   },
              // });
              formikProps?.setFieldValue('timesheetActivity', {
                value: res.data.data[0]?.id,
                label: res.data.data[0]?.activityname,
              });
            }
            setShowActivityField(false);
          }
        })
        .finally(() => {
          formikProps?.setSubmitting(false);
        });
      if (!formikProps.values.bookedUser.raw.isAgresso) {
        setShowActivityField(true);
      }
    }
  };

  const handleChangeActivity = (value: OptionType): void => {
    if (!value) {
      setErrorActivity(true);
    } else {
      setErrorActivity(false);
    }
    formikProps?.setFieldValue('timesheetActivity', value);
  };

  const handleChangeDate = (value: PulseDatePickerState) => {
    if (value?.values && value?.inputFocus) {
      const bookingDate = value.values;
      /**
       * Validate when type date input
       */
      if (!isValid(bookingDate.startDate)) {
        setSubmitting(true);
        console.warn('* Start date is Invalid');
        window?.utilities?.notification.danger('* Start date is Invalid');
        return;
      }
      if (!isValid(bookingDate.endDate)) {
        console.warn('* End date is Invalid');
        window?.utilities?.notification.danger('* End date is Invalid');
        setSubmitting(true);
        return;
      }
      setSubmitting(false);
      /**
       * End block
       */

      if (bookingDate.startDate && bookingDate.endDate) {
        const startDate = validBookingDate(endOfDay(bookingDate.startDate), resourceCalendar) as Date;
        const endDate = validBookingDate(endOfDay(bookingDate.endDate), resourceCalendar) as Date;

        if (!startDate || !endDate) {
          return;
        }

        const updatedStartDate = setTimeForADate(startDate, formikProps.values.startDate);
        const updatedEndDate = setTimeForADate(endDate, formikProps.values.endDate);

        formikProps.setFieldValue('startDate', new Date(updatedStartDate));
        formikProps.setFieldValue('endDate', roundToNearestMinutes(new Date(updatedEndDate), { nearestTo: 1 }));

        // check is same day and switch time picker
        if (isSameDay(endDate, startDate)) {
          const validNewDate = new Date(startDate.setHours(9, 0, 0));

          formikProps.setFieldValue('startDate', validNewDate);
          formikProps.setFieldValue('endDate', new Date(addHours(validNewDate, maxHours)));
        }

        const totalHours = calculateTotalHours({
          startDate: bookingDate.startDate,
          endDate: bookingDate.endDate,
          hoursPerDay: Number(formikProps?.values.hoursPerDay),
          calendar: resourceCalendar,
        });

        formikProps?.setFieldValue('totalHours', totalHours);
      } else {
        formikProps?.setFieldValue('totalHours', 0);
      }
    }
  };

  const onChangeStartTime = (time: Date[]) => {
    if (time.length > 0) {
      const startTime = setTimeForADate(formikProps.values.startDate, time[0]);
      const newStartDay = roundToNearestMinutes(new Date(startTime), { nearestTo: 1 });
      let newEndDay = formikProps.values.endDate;
      const hoursPerDay = Number(calculateHours(startTime, formikProps.values.endDate));
      if (hoursPerDay < 0) {
        newEndDay = startTime;
        formikProps.setFieldValue('endDate', startTime);
      }

      formikProps?.setFieldValue('startDate', newStartDay);
      const newHoursPerDay = Number(calculateHours(newStartDay, newEndDay));
      formikProps?.setFieldValue('hoursPerDay', newHoursPerDay);
    }
  };

  const onChangeEndTime = (time: Date[]) => {
    if (time.length > 0) {
      const endTime = setTimeForADate(formikProps.values.endDate, time[0]);
      let newStartDay = formikProps.values.startDate;
      const newEndDay = roundToNearestMinutes(new Date(endTime), { nearestTo: 1 });
      const hoursPerDay = Number(calculateHours(formikProps.values.startDate, endTime));
      if (hoursPerDay < 0) {
        newStartDay = endTime;
        formikProps.setFieldValue('startDate', endTime);
      }

      formikProps?.setFieldValue('endDate', newEndDay);
      const newHoursPerDay = Number(calculateHours(newStartDay, newEndDay));
      formikProps?.setFieldValue('hoursPerDay', newHoursPerDay);
    }
  };

  const [changingSlider, setChangingSlider] = useState<boolean>(true);

  const sliderBarChange = (value: number | number[]) => {
    setSliderRatio(Math.round((Number(value) / maxHours) * 10));
    const newEndDate = addHours(
      addMinutes(
        set(new Date(formikProps.values.startDate), {
          year: new Date(formikProps.values.endDate).getFullYear(),
          month: new Date(formikProps.values.endDate).getMonth(),
          date: new Date(formikProps.values.endDate).getDate(),
        }),
        (Number(value) - Math.floor(Number(value))) * 60,
      ),
      Number(value),
    );
    formikProps?.setFieldValue('hoursPerDay', Number(value));
    formikProps?.setFieldValue('totalHours', Number(value) * totalDays);
    formikProps?.setFieldValue('endDate', newEndDate);
  };

  const debounceChangeSliderBarFn = useCallback(debounce(sliderBarChange, 50), [totalDays]);

  const onChangeSliderBar = (e: Event, newValue: number | number[]) => {
    sliderValue.current = Number(newValue);
    formikProps?.setFieldValue?.('hoursPerDay', Number(newValue));
    setChangingSlider(true);
  };

  const onChangeStatus = (status: OptionType) => {
    formikProps?.setFieldValue?.('bookingStatus', status);
  };

  useEffect(() => {
    if (!changingSlider) {
      debounceChangeSliderBarFn(formikProps?.values?.hoursPerDay);
    }
    return () => {
      debounceChangeSliderBarFn.cancel();
    };
  }, [formikProps?.values?.hoursPerDay, changingSlider]);

  const onChangeDescription = debounce((description: string): void => {
    formikProps?.setFieldValue('description', description);
  }, 300);

  const userParams = {
    filter: {
      'user.client.id': allOfficesIds,
    },
  };
  const userParamsString = QueryString.stringify(userParams, { encode: false });

  /**
   * calculate total hours
   */
  useEffect(() => {
    formikProps?.setFieldValue('totalHours', formikProps.values.hoursPerDay * totalDays);
  }, [`${formikProps.values.startDate}`, `${formikProps.values.endDate}`, formikProps.values.hoursPerDay]);

  /**
   * This effect check when single day
   * when switch from multiple day to single hours per day should reset
   * when on Edit mode, that keep the hoursPerDay ( not rest to 8.00)
   */
  // useEffect(() => {
  //   if (Number(formikProps.values.hoursPerDay) === 0) {
  //     return;
  //   }
  //   if (isSingleDay) {
  //     let hoursPerDay = Number(calculateHours(formikProps.values.startDate, formikProps.values.endDate));

  //     if (!isNew) {
  //       // edit detail mode
  //       hoursPerDay = Number(calculateHours(formikProps.values.startDate, formikProps.values.endDate));
  //       const newTimeEndDate = recalculateEndHours(formikProps.values.startDate, hoursPerDay);
  //       console.log(newTimeEndDate, 'newTimeEndDate');
  //       formikProps.setFieldValue('endDate', newTimeEndDate);
  //     }
  //     formikProps.setFieldValue('hoursPerDay', hoursPerDay);

  //     formikProps?.setFieldValue('hoursPerDay', hoursPerDay);
  //   }
  // }, [isSingleDay]);

  /**
   * This effect update for slider and input
   */
  useEffect(() => {
    // update value Booking input ref when hours per day change
    if (bookingHoursInputRef.current) {
      (bookingHoursInputRef.current as any).value = Number(formikProps.values.hoursPerDay).toFixed(2);
    }
    // update value Booking slider ref when hours per day change
    if (sliderValue.current) {
      sliderValue.current = Number(formikProps.values.hoursPerDay).toFixed(2);
    }
  }, [formikProps.values.hoursPerDay]);

  useEffect(() => {
    if (formikProps.values?.bookedUser && formikProps.values?.job) {
      setActivityUrl(
        `resource-planner.php?action=get-activities-for-bookings&jobid=${formikProps.values?.job?.value}&userid=${formikProps.values?.bookedUser?.value}&useDefaultResourceBookingActivity=true`,
      );
      checkAvailableActivity();
    }
  }, [formikProps.values?.job, formikProps.values?.bookedUser]);
  return (
    <div className={styles['booking-details__ctn']}>
      <div className={styles['booking-details__selects']}>
        <PulseProjectsSelect
          minCharacters={3}
          value={formikProps?.values.job}
          valueChangeHandler={handleChangeProject}
          error={(formikProps?.errors.job?.['value'] || JOB_ERROR)?.toString()}
          isInvalid={errorProject}
          extraParams={{
            filter: {
              jobstatus: 'active',
            },
          }}
          isRequired
          isDisabled={(isNew && isNonChargeTime) || formikProps.values?.job?.isNC}
          variant="select2"
        />
        <div className={styles['booking-details__row']}>
          <PulseProjectUsersSelect
            labelName="BOOKED USER"
            value={formikProps?.values.bookedUser}
            valueChangeHandler={handleChangeUser}
            error={(formikProps?.errors?.bookedUser?.['value'] || BOOKED_USER_ERROR).toString()}
            isInvalid={errorBookedUser}
            extraParams={{
              filter: {
                pulse_active_status: 'active',
                bookable: 'y',
                exclude_agresso_termed: '1', // to exclude termed agresso users
                category_not_in: 'Client', // to exclude client users
              },
              jq:
                '{ meta: .meta, data: [ .included as $included | .data[] as $data | $data | .relationships.activeClients.data as $activeClients | .relationships.department.data as $departments | .relationships.roles.data as $roles | .relationships.defaultTimeSheet.data as $defaultTimeSheet | { label: .attributes.name, value: .id, avatarUrl: .attributes.avatarUrl, maxHours: .attributes.maxHours, isAgresso: .attributes.is_agresso, clientid: .attributes.clientid, clients: ( if $activeClients then $activeClients | map(. as $activeClient | $included[] | select(.type == "clients" and .id == $activeClient.id) | .attributes ) else null end ), defaultTimesheetActivity: ( if $defaultTimeSheet then $included[] | select(.type == $defaultTimeSheet.type and .id == $defaultTimeSheet.id) | .attributes else null end ), departments: ( if $departments then $included[] | select(.type == $departments.type and .id == $departments.id) | .attributes else { department_id: "", name: "No department" } end ), primaryClient: ( if $activeClients then $activeClients[0] as $primaryClient | $included[] | select(.type == "clients" and .id == $primaryClient.id) | .attributes else null end ), roles: ( if $roles then $included[] | select(.type == $roles.type and .id == $roles.id) | .attributes else { role_id: "", name: "No role" } end ), } ] }',
            }}
            url={rpState.bookingPermissions.isSuperUser ? BOOKED_USER_URL : `${BOOKED_USER_URL}&${userParamsString}`}
            dataStructure={BOOKED_USER_DATA_STRUCTURE}
            isRequired
            variant="select2"
          />
          <div className={styles['select-activity__container']}>
            <PulseSelectBase
              classes={{
                root:
                  formikProps.values?.bookedUser && formikProps.values?.job && showActivityField
                    ? ''
                    : styles['select-activity__hidden'],
              }}
              isDisabled={!formikProps.values?.bookedUser || !formikProps.values?.job}
              isSearchable={true}
              error={
                formikProps?.errors?.timesheetActivity && formikProps?.errors?.timesheetActivity['value']?.toString()
              }
              isInvalid={errorActivity}
              isRequired
              labelName="ACTIVITY"
              staticOptions={activityOptions}
              value={formikProps?.values.timesheetActivity}
              changeHandler={handleChangeActivity}
              TippyProps={{ appendTo: document.body }}
              dataStructure={null}
              cacheUniqs={[activityUrl]}
              variant="select2"
            />
          </div>
        </div>
        <div className={styles['booking-details__row']}>
          <PulseSelectBase
            dataStructure={null}
            isDisabled={!rpState.bookingPermissions.canEditBooking}
            labelName={'Booking Status'}
            isClearable={false}
            value={
              statusStaticOptions.find(option => option.value === formikProps?.values.bookingStatus?.value) ??
              formikProps?.values.bookingStatus
            }
            changeHandler={onChangeStatus}
            staticOptions={statusStaticOptions}
            variant="select2"
          />
          <div className={styles['select-activity__container']}>
            <BookingColor isNonChargeTime={!!isNonChargeTime} />
          </div>
        </div>
      </div>

      <Divider />

      <div className={styles['booking-details__time']}>
        <div className={styles['time-picker__ctn']}>
          <div className={styles['date-picker__date']}>
            <div className={styles['date-picker__select']}>
              <div className={styles['date-picker__label']}>
                <i className={clsx('fal fa-calendar-alt', styles['date-picker__icon'])} />
                <p className={styles['date-picker__text']}>BOOKING DATE(S)</p>
              </div>
              {useMemo(
                () => (
                  <PulseDatePicker
                    values={{
                      startDate: valuesContext?.startDate as Date,
                      endDate: valuesContext?.endDate as Date,
                    }}
                    onChange={handleChangeDate}
                    classes={{
                      root: styles['date-picker__root'],
                    }}
                    DatepickerProps={{
                      range: true,
                      variant: 'input',

                      DatePickerInputProps: {
                        KeyboardDatePickerStartProps: {
                          placeholder: 'Start Date',
                          InputAdornmentProps: {
                            className: styles['date-picker__icon'],
                            position: 'start',
                            disablePointerEvents: true,
                          },
                          keyboardIcon: <></>,
                        },
                        KeyboardDatePickerEndProps: {
                          placeholder: 'End Date',
                          InputAdornmentProps: {
                            className: styles['date-picker__icon'],
                            position: 'start',
                            disablePointerEvents: true,
                          },
                          keyboardIcon: <></>,
                        },
                        labelCommon: '',
                        spacer: {
                          classes: {
                            icon: 'fal fa-minus',
                          },
                          iconName: '',
                        },
                      },
                    }}
                  />
                ),
                [`${valuesContext?.startDate}`, `${valuesContext?.endDate}`],
              )}
            </div>
            <div className={styles['date-picker__info']}>
              <p className={styles['date-info__label']}>DAY(S)</p>
              <span className={styles['date-info__value']}>{totalDaysText}</span>
            </div>
          </div>

          {isSingleDay ? (
            <div className={styles['time-picker__time']}>
              <div className={styles['time-picker__select']}>
                <div className={styles['time-picker__label']}>
                  <i className={clsx('fal fa-clock', styles['time-picker__icon'])} />
                  <p className={styles['time-picker__text']}>START TIME/END TIME</p>
                </div>
                <div className={styles['time-picker__ctn']}>
                  <div className={styles['time-picker__field']}>
                    <PulseTimePicker
                      classes={{
                        textField: styles['time-picker__text-field'],
                      }}
                      onChange={onChangeStartTime}
                      placeholder="Start Time"
                      selectedTime={formikProps.values.startDate}
                      options={{
                        allowInput: false,
                        static: true,
                        position: 'below',
                      }}
                    />
                  </div>
                  <i className={clsx('fal fa-minus', styles['time-picker__link-icon'])} />
                  <div className={styles['time-picker__field']}>
                    <PulseTimePicker
                      classes={{
                        textField: styles['time-picker__text-field'],
                      }}
                      onChange={onChangeEndTime}
                      placeholder="End Time"
                      selectedTime={formikProps.values.endDate}
                      options={{
                        allowInput: false,
                        static: true,
                        position: 'below',
                      }}
                    />
                  </div>
                </div>
              </div>
              <Suspense fallback={'...'}>
                <BookingHoursInput isSingleDay={isSingleDay} ref={bookingHoursInputRef} totalDays={totalDays} />
              </Suspense>
            </div>
          ) : (
            <div className={styles['time-picker-time__slider']}>
              <div className={styles['slider-root']}>
                <p className={styles['slider-root__label']}>PERCENTAGE DAY BOOKING %</p>
                <Slider
                  valueLabelFormat={(value: number) =>
                    `${formikProps?.values?.hoursPerDay} hours - ${(value / maxHours) * 100}% daily capacity`
                  }
                  aria-label="Temperature"
                  value={sliderValue.current}
                  classes={{
                    colorPrimary: styles['slider-colors'],
                  }}
                  valueLabelDisplay="auto"
                  step={maxHours / 10}
                  onChange={(_, newValue) => {
                    sliderValue.current = Number(newValue);
                    formikProps.setFieldValue('hoursPerDay', newValue);
                  }}
                  onChangeCommitted={(e, newValue) => {
                    onChangeSliderBar(e as Event, newValue);
                    setChangingSlider(false);
                  }}
                  marks
                  min={0}
                  max={maxHours}
                />
              </div>
              <Suspense fallback={'...'}>
                <BookingHoursInput isSingleDay={isSingleDay} ref={bookingHoursInputRef} totalDays={totalDays} />
              </Suspense>
            </div>
          )}
        </div>
        <div className={styles['time-info']}>
          <p className={styles['time-info__label']}>TOTAL BOOKED HRS</p>
          <label
            className={clsx(
              styles['time-info__value'],
              formikProps?.values.totalHours < 0 && styles['time-info__value--invalid'],
            )}
          >
            {isNaN(Number(formikProps?.values.totalHours)) ? '0.00' : Number(formikProps?.values.totalHours).toFixed(2)}
            {isOverbook && (
              <PulseTooltip
                classes={{
                  childrenRoot: styles['warning__tooltips__children'],
                  tooltipRoot: styles['warning__tooltips__root'],
                }}
                TippyProps={{
                  content: (
                    <>
                      {overBookingList.map((booking: OverBookingList) => {
                        return (
                          <p key={booking.date} className={styles['time-info__value--overbook']}>
                            {format(parseISO(booking.date), 'dd/MM/yyyy')} {booking.overbooked_hours} hours overbooked
                          </p>
                        );
                      })}
                    </>
                  ),
                  placement: 'top',
                  popperOptions: {
                    strategy: 'fixed',
                  },
                  offset: [0, 5],
                }}
              >
                <i className="fal fa-info-circle"></i>
              </PulseTooltip>
            )}
          </label>
          {isProcessingOverbooking ? (
            <>
              <div className={styles['loading--dot']}></div>
              <div className={styles['loading--dot']}></div>
              <div className={styles['loading--dot']}></div>
            </>
          ) : (
            <label
              className={clsx(styles['time-info__sub-text'], isOverbook && styles['time-info__sub-text--warning'])}
            >
              {isOverbook ? 'Hours are beyond resource capacity' : 'Hours are within resource capacity'}
            </label>
          )}
        </div>
      </div>

      <Divider />

      <div className={styles['booking-details__description']}>
        <label className={styles['booking-details__description--label']}>DESCRIPTION</label>
        <PulseEditor
          footer={false}
          onEditorChange={onChangeDescription}
          value={formikProps?.values.description}
          mode="edit-only"
          {...PulseEditorProps}
          initSettings={{
            toolbar: 'bold italic underline link',
            placeholder: 'Enter Text For Description',
            ...PulseEditorProps.initSettings,
          }}
        />
      </div>
    </div>
  );
};
export default BookingDetails;
